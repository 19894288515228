import type React from "react"
import { createContext, useState, useContext, type ReactNode } from "react"
import { type FirmanteData, Size, Coordinates, QRData, TextValidationData } from "../types"
import { Outlet } from "react-router-dom"

interface DocumentTemplateContextType {
    nameDocument: string
    setNameDocument: (name: string) => void
    numeroFirmantes: number
    setNumeroFirmantes: (num: number) => void
    fileTemplate: File[]
    setFileTemplate: (files: File[]) => void
    textoLateral: string
    setTextoLateral: (text: string) => void
    isAñadirTexto: boolean
    setIsAñadirTexto: (value: boolean) => void
    isUrlValidacion: boolean
    setIsUrlValidacion: (value: boolean) => void
    isQR: boolean
    setQR: (value: boolean) => void
    location: string
    setLocation: (location: string) => void
    orientationValidation: string
    setOrientationValidation: (orientation: string) => void
    firmantes: FirmanteData[]
    setFirmantes: (firmantes: FirmanteData[]) => void
    currentPage: number
    setCurrentPage: (page: number) => void
    numPages: number | null
    setNumPages: (pages: number | null) => void
    QRData: QRData
    setQRData: (QR: QRData) => void
    TextValidationData: TextValidationData
    setTextValidationData: (TextValidation: TextValidationData) => void
}

const DocumentTemplateContext = createContext<DocumentTemplateContextType | undefined>(undefined)

export const DocumentTemplateProvider = () => {
    const [nameDocument, setNameDocument] = useState("")
    const [numeroFirmantes, setNumeroFirmantes] = useState(0)
    const [fileTemplate, setFileTemplate] = useState<File[]>([])
    const [textoLateral, setTextoLateral] = useState("")
    const [isAñadirTexto, setIsAñadirTexto] = useState(false)
    const [isUrlValidacion, setIsUrlValidacion] = useState(false)
    const [isQR, setQR] = useState(false)
    const [location, setLocation] = useState("Izquierda")
    const [orientation, setOrientation] = useState<string>("Vertical")
    const [firmantes, setFirmantes] = useState<FirmanteData[]>([])
    const [currentPage, setCurrentPage] = useState(1)
    const [numPages, setNumPages] = useState<number | null>(null)
    const [QRData, setQRData] = useState<QRData>({ size: { width: 0, height: 0 }, coordinates: { x: 0, y: 0 } })
    const [TextValidationData, setTextValidationData] = useState<TextValidationData>({ size: { width: 0, height: 0 }, coordinates: { x: 0, y: 0 } })

    return (
        <DocumentTemplateContext.Provider
            value={{
                nameDocument,
                setNameDocument,
                numeroFirmantes,
                setNumeroFirmantes,
                fileTemplate,
                setFileTemplate,
                textoLateral,
                setTextoLateral,
                isAñadirTexto,
                setIsAñadirTexto,
                isUrlValidacion,
                setIsUrlValidacion,
                isQR,
                setQR,
                location,
                setLocation,
                orientationValidation: orientation,
                setOrientationValidation: setOrientation,
                firmantes,
                setFirmantes,
                currentPage,
                setCurrentPage,
                numPages,
                setNumPages,
                QRData,
                setQRData,
                TextValidationData,
                setTextValidationData,
            }}
        >
            <Outlet />
        </DocumentTemplateContext.Provider>
    )
}

export const useDocumentTemplateContext = () => {
    const context = useContext(DocumentTemplateContext)
    if (context === undefined) {
        throw new Error("useDocumentTemplate must be used within a DocumentTemplateProvider")
    }
    return context
}

