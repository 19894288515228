import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import api from '../../shared/api/Axios';
import { StatisticEndpoints } from '../../shared/api/services/ConfigApi';
import { SummaryStadisticsBlock } from '../components/SummaryStadisticsBlock ';
import { DateRangeSelector } from '../components/DateRangeSelector';
import { Skeleton } from '../../shared/components/Skeleton';
import { OrganizationStadisticsBlock } from '../components/OrganizationStadisticsBlock';
import { StatisticRequest } from '../types/StatisticRequest';

const fetchStatistics = async (startDate: string, endDate: string): Promise<StatisticRequest> => {
    const response = await api.get(StatisticEndpoints.getStatistics(startDate, endDate))
    return response.data
}

export const StatisticsPage = () => {

    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);

    const handleSearch = (startDate: Date, endDate: Date) => {
        setDateRange({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        });
        setIsDateRangeSelected(true);
    };
    const [dateRange, setDateRange] = React.useState<{ startDate: string; endDate: string } | null>(null);

    const { data, error, isLoading, refetch } = useQuery<StatisticRequest>({
        queryKey: ['statistics', dateRange?.startDate, dateRange?.endDate],
        queryFn: async () => {
            if (!dateRange) throw new Error('Date range not set');
            return fetchStatistics(dateRange.startDate, dateRange.endDate);
        },
        enabled: !!dateRange,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: false,
    });
    if (error) return <div>Error al cargar los datos</div>

    return (
        <div className='bg-slate-100 flex justify-center p-3  '>
            <div className='flex flex-col gap-4 px-4 mx-auto min-w-md md:max-w-screen-lg size-full '>
                <SummaryStadisticsBlock
                    title='Consultar firmas'
                    description='Consulta el número de firmas realizado en un período de tiempo' >

                    <DateRangeSelector onSearch={handleSearch} />
                    {data && (
                        <p className="h6">
                            <span className="text-NeutralNeutral1">{data.totalSignatures} firmas</span>
                            <span className="text-NeutralNeutral2"> en total de </span>
                            <span className="text-NeutralNeutral1">{data.totalOrganizations} organizaciones</span>
                            <span className="text-NeutralNeutral2"> y </span>
                            <span className="text-NeutralNeutral1">{data.totalDocumentTemplates} templates</span>
                        </p>
                    )}

                </SummaryStadisticsBlock>
                {isLoading && (
                    <div className=" mt-4">
                        {Array(1)
                            .fill(0)
                            .map((_, i) => <Skeleton key={i} />)}
                    </div>
                )}
                {data?.organizationStatisticsResponses.map((org) => (
                    <OrganizationStadisticsBlock
                        key={org.organizationId}
                        title={org.organizationName}
                        description='Estadísticas de uso de la aplicación'
                        totalSignatures={org.totalSignatures}
                        documentTemplates={org.documentTemplateStatisticResponses}
                    />
                ))}
            </div>
        </div>

    )
}