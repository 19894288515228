import React from 'react';

interface DownloadIconProps {
    size?: number;
    color?: string;
    className?: string;
}

const DownloadIcon: React.FC<DownloadIconProps> = ({
    size = 36,
    color = '#94A3B8',
    className = '',
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 44 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M22 0.699219C22.9734 0.699219 23.7625 1.48832 23.7625 2.46172V15.5984L28.346 10.6624C29.0083 9.94912 30.1235 9.90782 30.8368 10.5702C31.5501 11.2325 31.5914 12.3477 30.9291 13.061L23.2915 21.286C22.9581 21.6452 22.4901 21.8492 22 21.8492C21.5099 21.8492 21.0419 21.6452 20.7085 21.286L13.071 13.061C12.4086 12.3477 12.4499 11.2325 13.1632 10.5702C13.8765 9.90782 14.9917 9.94912 15.654 10.6624L20.2375 15.5984V2.46172C20.2375 1.48832 21.0266 0.699219 22 0.699219Z"
                fill={color}
            />
            <path
                d="M10.8915 6.57422C9.57002 6.57422 8.4112 7.45665 8.05978 8.73056L4.48077 21.7044C4.46754 21.7524 4.45553 21.8007 4.44476 21.8492H12.6C13.4901 21.8492 14.3038 22.3521 14.7019 23.1483L15.7529 25.2502C16.1509 26.0463 16.9646 26.5492 17.8548 26.5492H25.934C26.7633 26.5492 27.5312 26.1121 27.9546 25.399L29.3793 22.9995C29.8027 22.2863 30.5707 21.8492 31.4 21.8492H39.5552C39.5445 21.8007 39.5325 21.7524 39.5192 21.7044L35.9402 8.73055C35.5888 7.45665 34.43 6.57422 33.1085 6.57422H30.8125C29.8391 6.57422 29.05 5.78512 29.05 4.81172C29.05 3.83832 29.8391 3.04922 30.8125 3.04922H33.1085C36.0158 3.04922 38.5652 4.99056 39.3383 7.79315L42.9173 20.767C43.0717 21.3268 43.15 21.9049 43.15 22.4856V31.2492C43.15 33.845 41.0457 35.9492 38.45 35.9492H5.55C2.95426 35.9492 0.85 33.845 0.85 31.2492V22.4856C0.85 21.9049 0.928271 21.3268 1.0827 20.767L4.6617 7.79316C5.43483 4.99057 7.98423 3.04922 10.8915 3.04922H13.1875C14.1609 3.04922 14.95 3.83832 14.95 4.81172C14.95 5.78512 14.1609 6.57422 13.1875 6.57422H10.8915Z"
                fill={color}
            />
        </svg>
    );
};

export default DownloadIcon;

