import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router/routes";
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext";

export const useFileTemplate = () => {
    const navigate = useNavigate();
    const { setFileTemplate, nameDocument, setNameDocument } = useDocumentTemplateContext();

    const removeAllFileTemplate = () => {
        setFileTemplate([])
    }

    const setNameDocumentAndNavigate = (value: string) => {
        setNameDocument(value)
        if (nameDocument.trim()) {
            navigate(`${ROUTES.CREATE_DOCUMENT_TEMPLATE}/${nameDocument}`);
        }
    }

    const navigateWithNameDocument = () => {
        if (nameDocument.trim()) {
            navigate(`${ROUTES.CREATE_DOCUMENT_TEMPLATE}/${nameDocument}`);
        }
    }

    return { removeAllFileTemplate, nameDocument, setNameDocumentAndNavigate, setNameDocument, navigateWithNameDocument }
}