import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import InputField from '../../shared/components/InputField'
import Button, { ButtonSize } from '../../shared/components/Button'
import { ROUTES } from '../../router/routes'
import { useDocumentTemplateContext } from '../context/DocumentTemplateContext'
import { useFileTemplate } from '../hooks/useFileTemplate'

interface AllTemplatesProps {
    // nombrePlantilla: string
    // onNombrePlantillaChange: (nombrePlantilla: string) => void
}




export const AllTemplatesPage = ({ }: AllTemplatesProps) => {
    const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [isModalCrearPlantilla, setIsModalCrearPlantilla] = useState(false)
    const { nameDocument, setNameDocumentAndNavigate, setNameDocument, navigateWithNameDocument } = useFileTemplate();

    return (
        <>
            <div>
                {array.map((i, index) => {
                    return <div className='grid grid-cols-4 items-center justify-center gap-4 size-full'>
                        Plantilla {index + 1}
                    </div>
                })}
                {/* <Link to={ROUTES.CREATE_DOCUMENT_TEMPLATE}> */}
                <Button size={ButtonSize.medium} text='Crear nueva plantilla' onClick={() => setIsModalCrearPlantilla(true)} />
                {/* </Link> */}
            </div>
            {isModalCrearPlantilla &&
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center "
                >
                    <div className="bg-white  p-6 rounded-lg w-96" onClick={(e) => e.stopPropagation()}>
                        <h2 className="h5 mb-4">Crear plantilla

                        </h2>
                        <div className='flex flex-col gap-4'>
                            <InputField placeholder='Nombre de plantilla' type='text' value={nameDocument} onChange={(e) => setNameDocument(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault()
                                        navigateWithNameDocument()
                                    }
                                }} />
                        </div>
                        <div className="flex justify-end mt-6 gap-4">
                            <Button
                                size={ButtonSize.medium}
                                onClick={() => setIsModalCrearPlantilla(false)}
                                text='Cancelar'
                                hasBorder={true}
                            />
                            <Link to={`${ROUTES.CREATE_DOCUMENT_TEMPLATE}/:${nameDocument}`} >
                                <Button
                                    size={ButtonSize.medium}
                                    onClick={navigateWithNameDocument}
                                    hasBg={true}
                                    text='Crear plantilla'
                                />
                            </Link>

                        </div>

                    </div>
                </div>
            }
        </>

    )
}
