import { useDocumentosContext } from '../context/DocumentosContext';
import { DocumentoGroupByFecha } from './DocumentoGroupByFecha';

interface ListaDocumentosProps {
    selectedDocuments?: string[]; // Propiedad opcional
    onDocumentSelect?: (documentId: string) => void; // Propiedad opcional
}

export const ListaDocumentos = ({ selectedDocuments, onDocumentSelect }: ListaDocumentosProps) => {
    const { documentosFiltradosGrouped } = useDocumentosContext();

    return (
        <div className='md: max-w-screen-lg items-center '>
            {documentosFiltradosGrouped.map(d => (
                <DocumentoGroupByFecha key={d.createdDay.toString()}
                    fecha={d.createdDay.toString()}
                    documentos={d.documents}
                    selectedDocuments={selectedDocuments}
                    onDocumentSelect={onDocumentSelect} />
            ))}
        </div>
    )
}