import { Rnd } from 'react-rnd';
import { Coordinates, QRData, Size, TextValidationData } from '../../types';

export interface DraggableBoxURLValidationProps {
    hasTextoLegal?: boolean,
    orientation: string
    hasUrl?: boolean
    coordinates: Coordinates
    size: Size
    setTextValidationData: (setTextValidationData: TextValidationData) => void
}

export const DraggableBoxURLValidation = ({ hasTextoLegal, orientation, hasUrl, coordinates, size, setTextValidationData }: DraggableBoxURLValidationProps) => {
    if (!(orientation === "Horizontal" || orientation === "Vertical"))
        return <></>

    const BoxURL = () => {
        const text = `${hasTextoLegal ? 'Texto Legal' : ''}${hasTextoLegal && hasUrl ? ' y ' : ''}${hasUrl ? 'URL de validación' : ''}`;
        return (
            <div className='relative bg-slate-100  border-dashed border-CorporatePrimary border-2 rounded-lg w-full h-full flex flex-col items-center justify-between'>
                {
                    orientation === 'Horizontal' ?
                        <span className="absolute -bottom-3 left-1/2 transform -translate-x-1/2  text-xs text-white bg-CorporatePrimary size-fit p-1.5 rounded-md whitespace-nowrap">
                            {text}
                        </span>
                        :
                        <div className="absolute inset-y-0 -right-24 flex items-center">
                            <div className="transform -rotate-90 origin-center">
                                <span
                                    className="block text-xs text-white bg-CorporatePrimary px-2 py-1 rounded-md whitespace-nowrap text-center w-48 text-ellipsis overflow-hidden"
                                >
                                    {text}
                                </span>
                            </div>
                        </div>
                }
            </div>
        )
    }

    return (
        <>
            {orientation === 'Vertical' ?
                <Rnd
                    default={{
                        x: coordinates.x,
                        y: coordinates.y,
                        width: size.width,
                        height: size.height
                    }}
                    minWidth={35}
                    maxWidth={35}
                    minHeight={200}
                    maxHeight={500}
                    dragGrid={[10, 10]}
                    onDragStop={(e, d) => {
                        setTextValidationData({
                            size,
                            coordinates: {
                                x: d.x,
                                y: d.y
                            }
                        });
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setTextValidationData({
                            size: {
                                width: ref.offsetWidth,
                                height: ref.offsetHeight,
                            },
                            coordinates
                        })
                    }}

                    bounds="parent"
                >
                    <BoxURL />
                </Rnd>
                :
                <Rnd
                    default={{
                        x: 150,
                        y: 205,
                        width: 200,
                        height: 35,
                    }}
                    minWidth={200}
                    maxWidth={500}
                    minHeight={35}
                    maxHeight={35}
                    dragGrid={[10, 10]}
                    onDragStop={(e, d) => {
                        setTextValidationData({
                            size,
                            coordinates: {
                                x: d.x,
                                y: d.y
                            }
                        });
                    }}
                    onResizeStop={(e, direction, ref, delta, position) => {
                        setTextValidationData({
                            size: {
                                width: ref.offsetWidth,
                                height: ref.offsetHeight,
                            },
                            coordinates
                        })
                    }}
                    bounds="parent"
                >
                    <BoxURL />
                </Rnd>
            }

        </>

    )
}
