import type React from "react"
import { useEffect, useRef, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString()

interface PDFViewerProps {
    file: File | null
    currentPage: number
    changePage: (page: number) => void
    onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void
    numPages: number | null
    children?: React.ReactNode

}

export const PDFViewer: React.FC<PDFViewerProps> = ({
    file,
    currentPage,
    onDocumentLoadSuccess,
    children,
}) => {
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
    const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 })
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setContainerSize({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                })
            }
        }

        window.addEventListener("resize", updateWidth)
        updateWidth()

        return () => window.removeEventListener("resize", updateWidth)
    }, [])

    const handlePageLoadSuccess = ({ width, height }: { width: number; height: number }) => {
        setPageDimensions({ width, height })
    }

    if (!file) {
        return null
    }

    return (
        <div ref={containerRef} className="w-full  h-full flex flex-col">
            <div className="flex-grow relative">
                <Document
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className="absolute inset-0 flex items-center justify-center"
                >
                    <Page
                        pageNumber={currentPage}
                        width={containerSize.width}
                        height={containerSize.height}
                        className="max-w-full max-h-full"
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        onLoadSuccess={handlePageLoadSuccess}
                    >
                        {children}
                    </Page>

                </Document>
            </div>
            <div className="text-sm text-gray-600 ">
                Dimensiones: {pageDimensions.width.toFixed(2)}px x {pageDimensions.height.toFixed(2)}px
            </div>
        </div>
    )
}

