import React from "react"
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"
import { Counter } from "../../shared/components/Counter"
import { SignatoryDropZone } from "./ui/SignatoryDropZone"
import { useSignature } from "../hooks/useSignature"

export const MenuDocumentTemplateManager = () => {
    const { handleAddFirmante, handleSubstractFirmante, handleNombreChange, handleAñadirLogoChange, handleFileLogoChange, handlePageSignatureChange, firmantes, numeroFirmantes } = useSignature()
    const { numPages } = useDocumentTemplateContext()

    const acceptedFileTypesLogo = {
        "image/png": [".png"],
    }

    return (
        <>
            <div className="flex justify-between items-center">
                <p className="bodyl text-NeutralNeutral1">Número de firmantes</p>
                <Counter count={numeroFirmantes} onAdd={handleAddFirmante} onSubtract={handleSubstractFirmante} max={5} />
            </div>
            <hr className="bg-NeutralNeutral5" />
            {numeroFirmantes > 0 &&
                firmantes.map((firmante, index) => (
                    <React.Fragment key={index}>
                        <SignatoryDropZone
                            index={index}
                            nombreFirma={firmante.nombreFirma}
                            isAñadirLogo={firmante.isAñadirLogo}
                            fileLogo={firmante.fileLogo}
                            onNombreChange={(value) => handleNombreChange(index, value)}
                            onAñadirLogoChange={(value) => handleAñadirLogoChange(index, value)}
                            onFileLogoChange={(files) => handleFileLogoChange(index, files)}
                            acceptedFileTypes={acceptedFileTypesLogo}
                            currentPage={firmante.page}
                            numberOfPages={numPages || 0}
                            onPageChange={(page) => handlePageSignatureChange(index, page)}
                        />
                        <hr className="bg-NeutralNeutral5" />
                    </React.Fragment>
                ))}
        </>
    )
}
