import { Link, useParams } from 'react-router-dom'
import { ROUTES } from '../../router/routes'
import { useDocumentTemplateContext } from '../context/DocumentTemplateContext'
import ReturnIcon from '../../shared/Icons/ReturnIcon'
import Button, { ButtonSize } from '../../shared/components/Button'
import { ChevronLeft } from '../../shared/Icons/ChevronLeft'
import InputField from '../../shared/components/InputField'
import { globals } from '../../shared/utils/Globals/global'
import { useFileTemplate } from '../hooks/useFileTemplate'
import { useEffect } from 'react'
import { useAuthContext } from '../../auth/context/AuthContext'


export const TemplateTopNav = () => {
    const { nameTemplate } = useParams<{ nameTemplate?: string }>()
    const { nameDocument, setNameDocument, navigateWithNameDocument, setNameDocumentAndNavigate } = useFileTemplate()
    const { selectedOrg } = useAuthContext()
    useEffect(() => {
        if (nameTemplate) {
            setNameDocument(nameTemplate)
        } else if (!nameDocument) {
            setNameDocument("Nombre de la plantilla")
        }
    }, [])

    return (
        <nav style={{ height: globals.ALTURA_TOPNAV }} className='flex flex-col'>
            <nav className="flex justify-between items-center w-full px-8 py-4 bg-white">
                <Link to={ROUTES.ALL_TEMPLATES as string}>
                    <ReturnIcon />
                </Link>
                <div className="flex-grow flex justify-start items-center ml-4 gap-2">
                    <p className="button16 text-NeutralNeutral2">
                        {selectedOrg?.name}
                    </p>
                    <ChevronLeft />
                    <InputField
                        placeholder={nameDocument}
                        type='text'
                        value={nameDocument}
                        onChange={(e) => setNameDocument(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setNameDocumentAndNavigate(nameDocument);
                            }
                        }}
                        whithOutBackground={true}
                        textColor='text-NeutralNeutral1'
                    />

                </div>
                <div className='flex gap-4'>
                    <Button
                        text="Vista previa"
                        size={ButtonSize.medium}
                        hasBg={false}
                        hasBorder={true}
                    />
                    <Button
                        text="Guardar"
                        size={ButtonSize.medium}
                        hasBg={true}
                        hasBorder={false}
                    />
                </div>

            </nav>
            <hr className='bg-NeutralNeutral6' />
        </nav>

    )
}
