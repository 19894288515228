import type React from "react"
import { Pagination } from "../../shared/components/Pagination"
import { PDFViewer } from "../../shared/components/PdfViewer"
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"
import { usePagination } from "../hooks/usePagination"
import { useSignature } from "../hooks/useSignature"
import { DraggableBoxQR } from "./ui/DraggableBoxQR"
import { DraggableBoxSignature } from "./ui/DraggableBoxSignature"
import { DraggableBoxURLValidation } from "./ui/DraggableBoxURLValidation"

export const DocumentTemplateViewer: React.FC = () => {
    const {
        fileTemplate,
        isUrlValidacion,
        isAñadirTexto,
        orientationValidation: orientation,
        isQR,
        QRData,
        setQRData,
        TextValidationData,
        setTextValidationData
    } = useDocumentTemplateContext()

    const { currentPage, numPages, onDocumentLoadSuccess, handlePageChange } = usePagination()
    const { firmantes, handleSizeSignatureChange, handleCoordinatesSignatureChange } = useSignature()

    return (
        <div className="w-full h-full relative overflow-clip">
            <div className="absolute inset-0 z-0">
                <PDFViewer
                    file={fileTemplate[0]}
                    numPages={numPages}
                    currentPage={currentPage}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                    changePage={handlePageChange}
                >
                    <div className="absolute inset-0 flex items-center justify-center">
                        {firmantes.map(
                            (firmante, index) =>
                                currentPage === firmante.page && (
                                    <DraggableBoxSignature
                                        key={index}
                                        index={index}
                                        currentPage={currentPage}
                                        onSizeChange={(value) => {
                                            handleSizeSignatureChange(index, value)
                                        }}
                                        size={firmante.size}
                                        coordinates={firmante.coordinates}
                                        onCoordinatesChange={(value) => {
                                            handleCoordinatesSignatureChange(index, value)
                                        }}
                                    />
                                ),
                        )}
                        {(isUrlValidacion || isAñadirTexto) && (
                            <DraggableBoxURLValidation
                                hasTextoLegal={isAñadirTexto}
                                hasUrl={isUrlValidacion}
                                orientation={orientation}
                                coordinates={TextValidationData.coordinates}
                                size={TextValidationData.size}
                                setTextValidationData={setTextValidationData}
                            />
                        )}
                        {isQR &&
                            <DraggableBoxQR
                                coordinates={QRData.coordinates}
                                size={QRData.size}
                                setQRData={setQRData}
                            />}
                    </div>
                </PDFViewer>
            </div>
            <div className="absolute bottom-0 left-0 right-0 flex justify-center z-50">
                <Pagination page={currentPage} totalCount={numPages || 0} pageSize={1} onPageChange={handlePageChange} />
            </div>
        </div>
    )
}

