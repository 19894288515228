import { DocumentTemplateProvider } from '../context/DocumentTemplateContext';
import { Navigate, Route, Routes } from "react-router-dom";
import { DocumentTemplateLayout } from '../layouts/DocumentTemplateLayout';
import { AllTemplatesPage } from '../pages/AllTemplatesPage';
import { ROUTES } from '../../router/routes';
import { CreateDocumentTemplateLayout } from '../layouts/CreateDocumentTemplateLayout';
import { CreateDocumentTemplate } from '../pages/CreateDocumentTemplatePage';

export const TemplatesRoutes = () => {
    return (
        <>
            <Route element={<DocumentTemplateProvider />}>
                <Route element={<DocumentTemplateLayout />}>
                    <Route path={ROUTES.ALL_TEMPLATES} element={<AllTemplatesPage />} />
                </Route>

                <Route element={<CreateDocumentTemplateLayout />}>
                    <Route
                        path={`${ROUTES.CREATE_DOCUMENT_TEMPLATE}/:nameTemplate`}
                        element={<CreateDocumentTemplate />}
                    />
                </Route>
            </Route>
        </>
    )
}
