import { format } from "date-fns";
import React from "react";
import Button, { ButtonSize } from "../../shared/components/Button";
import { DateInput } from "../../shared/components/DateInput";


interface DateRangeSelectorProps {
    onSearch: (startDate: Date, endDate: Date) => void
}

export function DateRangeSelector({ onSearch }: DateRangeSelectorProps) {
    const [startDate, setStartDate] = React.useState<Date>(new Date())
    const [endDate, setEndDate] = React.useState<Date>(new Date())

    const handleSearch = () => {
        onSearch(startDate, endDate)
    }

    return (
        <div className="flex flex-nowrap gap-5 justify-between items-end grow">
            <div className="flex size-full gap-3 justify-between ">
                <DateInput id="start-date" label="Fecha de inicio" value={startDate} onChange={setStartDate} />
                <DateInput id="end-date" label="Fecha fin" value={endDate} onChange={setEndDate} />
            </div>
            <div className="flex">
                <Button onClick={handleSearch}
                    size={ButtonSize.medium}
                    hasBg={true}
                    text="Consultar"
                />
            </div>

        </div>

    )
}