import { useState } from 'react'
import { HelpCircle } from 'lucide-react'

interface HelperProps {
    label: string
}

export function Helper({ label }: HelperProps) {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false)

    return (
        <div
            className="relative"
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
            onFocus={() => setIsTooltipVisible(true)}
            onBlur={() => setIsTooltipVisible(false)}
        >
            <HelpCircle
                className="size-6 text-NeutralNeutral2 cursor-help"
                role="button"
                tabIndex={0}
                aria-label="Mostrar ayuda"
            />
            {isTooltipVisible && (
                <div
                    className="absolute right-0 top-full mt-2 p-3 bg-white rounded-lg shadow-lg border border-gray-200 max-w-xs z-50  whitespace-nowrap"
                    role="tooltip"
                >
                    <div className="absolute -top-2 right-3 w-4 h-4 bg-white border-t border-l border-gray-200 transform rotate-45" />
                    <p className="bodys text-NeutralNeutral1 relative z-10">{label}</p>
                </div>
            )}
        </div>
    )
}

