import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"

export const usePagination = () => {
    const { currentPage, setCurrentPage, numPages, setNumPages } = useDocumentTemplateContext()

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages)
        setCurrentPage(1)
    }

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage)
    }

    return { currentPage, numPages, onDocumentLoadSuccess, handlePageChange }
}