import type React from "react"

interface ChevronLeftProps {
    width?: number
    height?: number
    color?: string
}

export const ChevronLeft: React.FC<ChevronLeftProps> = ({ width = 5, height = 8, color = "#64758B" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.78033 0.21967C5.07322 0.512563 5.07322 0.987437 4.78033 1.28033L2.06066 4L4.78033 6.71967C5.07322 7.01256 5.07322 7.48744 4.78033 7.78033C4.48744 8.07322 4.01256 8.07322 3.71967 7.78033L0.46967 4.53033C0.176777 4.23744 0.176777 3.76256 0.46967 3.46967L3.71967 0.21967C4.01256 -0.0732233 4.48744 -0.0732233 4.78033 0.21967Z"
                fill={color}
            />
        </svg>
    )
}
