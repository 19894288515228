import React from 'react'

interface GridIconProps {
    color?: string
    size?: number
}

export const GridIcon: React.FC<GridIconProps> = ({
    color = '#64758B',
    size = 22,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
        >
            <path
                d="M1.375 2.6875C1.375 1.96263 1.96263 1.375 2.6875 1.375H7.9375C8.66237 1.375 9.25 1.96263 9.25 2.6875V7.9375C9.25 8.66237 8.66237 9.25 7.9375 9.25H2.6875C1.96263 9.25 1.375 8.66237 1.375 7.9375V2.6875Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.375 14.0625C1.375 13.3376 1.96263 12.75 2.6875 12.75H7.9375C8.66237 12.75 9.25 13.3376 9.25 14.0625V19.3125C9.25 20.0374 8.66237 20.625 7.9375 20.625H2.6875C1.96263 20.625 1.375 20.0374 1.375 19.3125V14.0625Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.75 2.6875C12.75 1.96263 13.3376 1.375 14.0625 1.375H19.3125C20.0374 1.375 20.625 1.96263 20.625 2.6875V7.9375C20.625 8.66237 20.0374 9.25 19.3125 9.25H14.0625C13.3376 9.25 12.75 8.66237 12.75 7.9375V2.6875Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.875 4.875H5.75V5.75H4.875V4.875Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.875 16.25H5.75V17.125H4.875V16.25Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.25 4.875H17.125V5.75H16.25V4.875Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.75 12.75H13.625V13.625H12.75V12.75Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.75 19.75H13.625V20.625H12.75V19.75Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.75 12.75H20.625V13.625H19.75V12.75Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.75 19.75H20.625V20.625H19.75V19.75Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.25 16.25H17.125V17.125H16.25V16.25Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

