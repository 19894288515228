"use client"

import React, { useState, useRef } from 'react';
import { EyeOffIcon } from '../Icons/EyeOffIcon';
import { EyeIcon } from '../Icons/EyeIcon';

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder: string;
  type: "text" | "number" | "password";
  value: string;
  height?: number;
  whithOutBackground?: boolean;
  textColor?: string
}

const InputField = ({ label, placeholder, type, value, onChange, height, whithOutBackground: whithoutBackground = false, textColor = "text-NeutralNeutral2", ...props }: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const togglePasswordVisibility = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    // Mantener el foco en el input
    inputRef.current?.focus();
  };

  return (
    <div className='flex flex-col  justify-between items-start flex-nowrap gap-2'>
      {label && <label htmlFor={label} className="bodyl leading-6 text-NeutralNeutral3">{label}</label>}
      <div className="relative w-full ">
        <input
          ref={inputRef}
          type={type === 'password' && showPassword ? 'text' : type}
          name={label}
          id={label}
          className={` py-2 w-full bodymbold  ${textColor} placeholder:bodyl placeholder:text-NeutralNeutral3 sm:leading-6 outline-none rounded-md transition-colors duration-200 ease-in-out pr-10 focus:ring-1 focus:ring-inset  focus:border-2 focus:border-CorporatePrimary
                     ${height && `h-${height}`}
                     ${!whithoutBackground && 'px-4 bodyl focus:ring-1 focus:ring-inset  bg-NeutralNeutral6 '}
                     ${whithoutBackground && 'hover:bg-NeutralNeutral6 hover:border-2 hover:border-NeutralNeutral5'}
                     `}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              (e.target as HTMLInputElement).blur();
            }
          }}
          {...props}
        />
        {type === 'password' && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-NeutralNeutral3 hover:text-NeutralNeutral1 focus:outline-none"
            tabIndex={-1} // Evita que el botón reciba el foco
          >
            {showPassword ? (
              <EyeIcon />
            ) : (
              <EyeOffIcon />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default InputField;

