import { useState } from 'react';
import { Rnd } from 'react-rnd';
import { Coordinates, Size } from '../../types';
import { ImageIcon } from '../../../shared/Icons/Image';

export interface DraggableBoxSignatureProps {
    index: number;
    pageSignature?: number;
    currentPage?: number;
    size: Size;
    coordinates: Coordinates;
    onSizeChange: (size: Size) => void;
    onCoordinatesChange: (coordinates: Coordinates) => void;
}

export const DraggableBoxSignature = ({ index, size, onCoordinatesChange, coordinates, onSizeChange }: DraggableBoxSignatureProps) => {

    const [sizeImageIcon, setSizeImageIcon] = useState({ width: size.width, height: size.height })

    const Box = () => {
        return (
            <div className='relative  border-dashed border-CorporatePrimary border-2 rounded-lg w-full h-full flex justify-evenly items-center'>
                <div className='flex  size-full justify-between items-center space-x-4'>
                    <ImageIcon height={sizeImageIcon.height} width={sizeImageIcon.width} />

                    <span className='bodym  size-full text-center overflow-hidden line-clamp-2 text-NeutralNeutral1 text-ellipsis'>Firmado por {'{nombre}'} el dd/mm/yyyyy</span>

                </div>


                <span className="absolute -bottom-3 left-1/2 transform -translate-x-1/2  text-xs text-white bg-CorporatePrimary size-fit p-1.5 rounded-md ">
                    {`Firma ${index + 1}`}
                </span>
            </div>
        )
    }

    return (

        <Rnd
            default={{
                x: coordinates.x,
                y: coordinates.y,
                width: size.width,
                height: size.height
            }}
            minWidth={60}
            maxWidth={300}
            minHeight={40}
            maxHeight={80}
            dragGrid={[10, 10]}

            onDragStop={(e, d) => {
                onCoordinatesChange({ x: d.x, y: d.y });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                onSizeChange({
                    height: ref.offsetHeight,
                    width: ref.offsetWidth
                })

            }}
            onResize={(e, direction, ref, delta, position) => {
                setSizeImageIcon({ width: ref.offsetWidth, height: ref.offsetHeight });
            }}
            bounds="parent"
        >
            <Box />
        </Rnd>
    )
}
