import { Outlet } from 'react-router-dom'
import { TemplateTopNav } from '../navs/CreateTemplateTopNav'

export const CreateDocumentTemplateLayout = () => {
    return (
        <div className='flex flex-col'>
            {/* <MainTopNav /> */}
            <TemplateTopNav />
            <Outlet />
        </div>
    )
}
