import React, { useState } from 'react'

export interface CounterProps {
    count: number;
    onAdd: () => void;
    onSubtract: () => void;
    max?: number;
}
export const Counter = ({ count, onAdd, onSubtract, max = 5 }: CounterProps) => {

    const isSubstractDisabled = count === 0
    const isAddDisabled = count === max

    return (
        <div className='flex size-fit gap-4  justify-center items-center '>
            <button
                onClick={onSubtract}
                disabled={count === 0}
                className={
                    `bg-CorporatePrimary rounded-full size-8 flex items-center justify-center text-white relative
                    ${isSubstractDisabled ? ' bg-NeutralNeutral5' : ''}`

                }>
                <div className="w-[10px] h-[2px] bg-white absolute"></div>
            </button>
            <div className='bg-NeutralNeutral6 rounded-md text-NeutralNeutral1 button16 px-3 py-2 flex justify-center items-center'>
                {count}
            </div>
            <button
                onClick={onAdd}
                disabled={count === max}
                className={
                    `bg-CorporatePrimary rounded-full size-8 flex items-center justify-center text-white
                    ${isAddDisabled ? ' bg-NeutralNeutral5' : ''}`

                }>
                <div className="w-[10px] h-[2px] bg-white absolute"></div>
                <div className="w-[2px] h-[10px] bg-white absolute"></div>
            </button>
        </div>
    )
}
