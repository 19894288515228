
import { useState } from 'react';
import { Rnd } from 'react-rnd';
import { GridIcon } from '../../../shared/Icons/GridIcon';
import { Coordinates, QRData, Size } from '../../types';

export interface DraggableBoxQRProps {
    coordinates: Coordinates
    size: Size
    setQRData: (setQRData: QRData) => void
}

export const DraggableBoxQR = ({ coordinates, size, setQRData }: DraggableBoxQRProps) => {
    const [sizeQR, setSizeQR] = useState(size.width);
    const Box = () => {
        return (
            <div className='relative bg-slate-100 flex justify-center items-center border-dashed border-CorporatePrimary border-2 rounded-lg w-full h-full p-2'>

                <GridIcon size={sizeQR} />
                <span className="absolute -bottom-3 left-1/2 transform -translate-x-1/2  text-xs text-white bg-CorporatePrimary size-fit p-1.5 rounded-md ">
                    QR
                </span>
            </div>
        )
    }

    return (
        <Rnd
            default={{
                x: 150,
                y: 205,
                width: 45,
                height: 45,
            }}
            minWidth={45}
            maxWidth={150}
            minHeight={45}
            maxHeight={150}
            dragGrid={[10, 10]}
            lockAspectRatio={true}
            onResize={(e, direction, ref, delta, position) => {
                setSizeQR(size.width + delta.width);
            }}
            onDragStop={(e, d) => {
                setQRData({
                    size,
                    coordinates: {
                        x: d.x,
                        y: d.y
                    }
                });
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                setQRData({
                    size: {
                        width: ref.offsetWidth,
                        height: ref.offsetHeight,
                    },
                    coordinates
                })
            }}

            bounds="parent"
        >
            <Box />
        </Rnd>
    )
}
