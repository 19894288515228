"use client"

import { useState } from "react"
import { ChevronDown, ChevronUp } from "lucide-react"

export interface AccordionProps {
    children: React.ReactNode;
    title: React.ReactNode;
    titleNoClick?: boolean;
}

export const Accordion = ({ children, title, titleNoClick }: AccordionProps) => {
    const [isAccordion, setIsAccordion] = useState(false)

    return (
        <div className="size-full flex flex-col  gap-3 ">
            {
                titleNoClick ?
                    <div className="w-full flex justify-between items-center ">
                        <div className="w-10/12">

                            {title}
                        </div>
                        <button className={`transform transition-transform duration-300 ${isAccordion ? "rotate-180" : ""}`}
                            onClick={() => setIsAccordion(!isAccordion)}
                        >

                            <ChevronDown size={24} />
                        </button>

                    </div>
                    : <button
                        className="w-full flex justify-between items-center "
                        onClick={() => setIsAccordion(!isAccordion)}
                    >
                        {title}
                        <div className={`transform transition-transform duration-300 ${isAccordion ? "rotate-180" : ""}`}>
                            <ChevronDown size={24} />
                        </div>
                    </button>
            }

            {
                isAccordion &&
                children
            }

        </div>
    )
}

