import React, { useEffect, useState } from "react";
import api from "../../shared/api/Axios";

interface Faq {
  id: number;
  question: string;
  answer: string;
}

interface FaqProps {
  culture: string;
}

export const Faqs: React.FC<FaqProps> = ({ culture }) => {
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await api.get<Faq[]>(`/Faqs/GetAllFaqs?culture=Spanish`, {
          params: { culture },
        });
        setFaqs(response.data);
      } catch (err: any) {
        setError(err.message || "Failed to fetch FAQs");
      }
    };

    fetchFaqs();
  }, [culture]);

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  if (error) {
    return <p className="text-red-500">Error: {error}</p>;
  }

  return (
    <div className="flex flex-col mt-4 max-w-screen-lg w-full m-auto bg-white h-full rounded-t-lg">
      <h1 className="text-2xl font-bold mb-4 gap-4 p-4">Ayuda</h1>
      {faqs.map((faq, index) => (
        <div key={faq.id} className="border">
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full p-2 text-left flex justify-between items-center hover:font-bold"
          >
            <span>{openIndex === index ? "▲" : "▼"}</span>
            <span className="font-medium w-full p-3">{faq.question}</span>
          </button>
          {openIndex === index && (
            <div className="p-4 bg-white border-t">{faq.answer}</div>
          )}
        </div>
      ))}

      <div className="text-center h-10 text-gray-400 text-lg font-bold">EducaSign v. 0.0.1</div>
    </div>
  );
};
