import { useState } from 'react'
import { globals } from '../../utils/Globals/global'
import { DesktopNavigation } from './DesktopNavigation'
import { MobileMenuButton } from './MobileMenuButton'
import { MobileMenuNavigation } from './MobileMenuNavigation'
import { UserAvatar } from './UserAvatar'

interface MainTopNavProps {
    refetchDocuments?: (page: number) => void;
}


export default function MainTopNav({ refetchDocuments }: MainTopNavProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [activeButtonNav, setActiveButtonNav] = useState(1)

    return (
        <nav style={{ height: globals.ALTURA_TOPNAV }} className="flex justify-between items-center p-4  bg-white">
            <MobileMenuButton isOpen={isOpen} toggleMenu={() => setIsOpen(!isOpen)} />
            <DesktopNavigation
                activeButtonNav={activeButtonNav}
                setActiveButtonNav={setActiveButtonNav}
                refetchDocuments={refetchDocuments}
            />
            <UserAvatar />
            <MobileMenuNavigation
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                activeButtonNav={activeButtonNav}
                setActiveButtonNav={setActiveButtonNav}

            />
        </nav>
    )
}
