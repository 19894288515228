import { Outlet } from "react-router-dom"
import { useDocumentosContext } from "../../documents/context/DocumentosContext"
import MainTopNav from "../components/nav/MainTopNav"
export const TopNavLayoutWithDocumentContext = () => {
  const { refetchDocuments } = useDocumentosContext()
  return (
    <div className="flex flex-col bg-NeutralNeutral6  h-lvh">

      <MainTopNav refetchDocuments={refetchDocuments} />
      <main className="flex-grow">
        <Outlet />
      </main>
      {/* <Footer version={process.env.REACT_APP_VERSION || '0.0.0'} /> */}
    </div>
  )
}




export const TopNavLayout = () => {
  return (
    <div className='flex flex-col bg-NeutralNeutral6  h-lvh'>
      <MainTopNav />
      {/* <TemplateTopNav /> */}
      <main className="flex-grow">
        <Outlet />
      </main>
    </div>
  )
}

