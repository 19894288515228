import { useLocalStorage } from "../../shared/hooks/useLocalStorage"
import { Organization } from "../types/GetMeRequest"
import { LocalStorageKeys } from "../types/LocalStorageKeys"

export const useInfoLocalStorage = () => {
    const { getItem: getAccessToken, removeItem: removeAccessToken, setItem: setAccessToken } = useLocalStorage(LocalStorageKeys.accessToken)
    const { getItem: getRefreshToken, removeItem: removeRefreshToken, setItem: setRefreshToken } = useLocalStorage(LocalStorageKeys.refreshToken)
    const { getItem: getOrganizationsLocalStorage, removeItem: removeOrganizationsLocalStorage, setItem: setOrganizationsLocalStorage } = useLocalStorage(LocalStorageKeys.organizations)
    const { getItem: getUserOrganizationLoggedLocalStorage, removeItem: removeUserOrganizationLoggedLocalStorage, setItem: setUserOrganizationLoggedLocalStorage } = useLocalStorage(LocalStorageKeys.userOrganizationLogged)
    const { getItem: getNameUserLocalStorage, removeItem: removeNameUserLocalStorage, setItem: setNameUserLocalStorage } = useLocalStorage(LocalStorageKeys.nameUser)
    const { getItem: getMailUserLocalStorage, removeItem: removeMailUserLocalStorage, setItem: setMailUserLocalStorage } = useLocalStorage(LocalStorageKeys.mailUser)
    const { getItem: getExternalUserIdLocalStorage, removeItem: removeExternalUserIdLocalStorage, setItem: setExternalUserIdLocalStorage } = useLocalStorage(LocalStorageKeys.externalUserId)
    const { clearAll: clearAllLocalStorage } = useLocalStorage()


    return {
        accessToken: getAccessToken(),
        refreshToken: getRefreshToken(),
        getOrganizationsLocalStorage,
        getUserOrganizationLoggedLocalStorage,
        getNameUserLocalStorage,
        getMailUserLocalStorage,
        getExternalUserIdLocalStorage,
        setAccessToken,
        setRefreshToken,
        setOrganizationsLocalStorage,
        setUserOrganizationLoggedLocalStorage,
        setNameUserLocalStorage,
        setMailUserLocalStorage,
        setExternalUserIdLocalStorage,
        removeAccessToken,
        removeRefreshToken,
        removeOrganizationsLocalStorage,
        removeUserOrganizationLoggedLocalStorage,
        removeNameUserLocalStorage,
        removeMailUserLocalStorage,
        removeExternalUserIdLocalStorage,
        clearAllLocalStorage
    }
}