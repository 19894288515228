

import React from 'react';
import InputField from '../../../shared/components/InputField';
import { Toggle } from '../../../shared/components/Toggle';
import { DropZone, MaxDimensions, MinDimensions } from '../../../shared/components/DropZone';
import { Accept } from 'react-dropzone/.'
import { Dropdown } from '../../../shared/components/DropDown';
import { Accordion } from '../../../shared/components/Accordion';

interface FirmanteProps {
  index: number;
  nombreFirma: string;
  isAñadirLogo: boolean;
  fileLogo: File[];
  onNombreChange: (value: string) => void;
  onAñadirLogoChange: (value: boolean) => void;
  onFileLogoChange: (files: File[]) => void;
  acceptedFileTypes?: Accept,
  currentPage: number
  numberOfPages: number
  onPageChange: (page: number) => void
}

const minLogoDimensions: MinDimensions = {
  Width: 250,
  Height: 250,
};


export const SignatoryDropZone: React.FC<FirmanteProps> = ({
  index,
  nombreFirma,
  isAñadirLogo,
  fileLogo,
  onNombreChange,
  onAñadirLogoChange,
  onFileLogoChange,
  acceptedFileTypes,
  currentPage,
  numberOfPages,
  onPageChange

}) => {

  return (
    <div className='flex flex-col justify-center gap-2 items-center'>
      <div className='flex justify-start items-center size-full'>
        {/* <p className='bodymbold text-NeutralNeutral2 text-start text-nowrap pr-3'>Firma {index + 1}:</p> */}

        <Accordion title={
          <InputField
            placeholder={`Firma ${index + 1}`}
            type='text'
            value={nombreFirma}
            onChange={(e) => onNombreChange(e.target.value)}
            whithOutBackground={true}
          />
        }
          titleNoClick={true}>
          <div className='size-full justify-start'>
            <Dropdown
              label="Página"
              options={Array.from(Array(numberOfPages).keys()).map(i => (i + 1).toString())}
              value={currentPage.toString()}
              onChange={(e) => onPageChange(parseInt(e))}
            />

          </div>

          <div className='w-full'>
            <Toggle
              isChecked={isAñadirLogo}
              onChange={onAñadirLogoChange}
              label='Añadir logo'
            />
          </div>
          {isAñadirLogo && (
            <>
              <DropZone
                files={fileLogo}
                onFilesChange={onFileLogoChange}
                acceptedFileTypes={acceptedFileTypes}
                minDimensions={minLogoDimensions}
              />
              {fileLogo.length === 0 &&
                <>
                  <p className='bodym text-NeutralNeutral2'>Formato aceptado: PNG</p>
                  <p className='bodym text-NeutralNeutral2'> Mínima resolución: 250x250 px</p>
                </>

              }

            </>
          )}
        </Accordion>

      </div>

    </div>

  );
};

