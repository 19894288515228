import { error } from "console";
const TEMPLATES = "/templates";
const CREATE_TEMPLATE = `${TEMPLATES}/create`;

export const ROUTES = {
  INICIO: "/",
  LOGIN: "/login",
  EXTERNALLOGIN: "/ExternalLogin",
  LOGINERROR: "/login-error",
  VALIDACION: "/validation",
  VISOR_DOCUMENTOS: "/document-viewer",
  ESTADISTICAS: "/statistics",
  FAQS: "/Faqs",
  ALL_TEMPLATES: TEMPLATES,
  CREATE_DOCUMENT_TEMPLATE: CREATE_TEMPLATE,
};
