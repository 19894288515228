import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../auth/context/AuthContext';
import { useAuth } from '../../../auth/hooks/useAuth';
import { ROUTES } from '../../../router/routes';
import CheckCircle from '../../Icons/CheckCircle';
import CloseIcon from '../../Icons/CloseIcon';
import InboxIcon from '../../Icons/InboxIcon';
import { OrganizationIcon } from '../../Icons/OrganizationIcon';

interface MobileMenuProps {
    isOpen: boolean;
    onClose: () => void;
    activeButtonNav: number;
    setActiveButtonNav: (number: number) => void;

}

interface MobileMenuProps {
    isOpen: boolean;
    activeButtonNav: number;
    setActiveButtonNav: (number: number) => void;
    refetchDocuments?: (page: number) => void;

}

interface MobileMenuHeaderProps {
    onClose: () => void;
}


interface MobileMenuNavigationProps {
    setActiveButtonNav: (number: number) => void
}


export const MobileMenuNavigation: React.FC<MobileMenuProps> = ({ isOpen, onClose, setActiveButtonNav, refetchDocuments }) => {

    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if (isOpen) {
            setIsVisible(true)
        } else {
            const timer = setTimeout(() => setIsVisible(false), 300)
            return () => clearTimeout(timer)
        }
    }, [isOpen])

    if (!isVisible) return null

    return (
        <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0  pointer-events-none'}`} onClick={onClose}>
            <div
                className={`fixed top-0 left-0 bottom-0 w-80 bg-white shadow-lg transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'
                    } flex flex-col h-full`}
                onClick={(e) => e.stopPropagation()}
            >
                <MobileMenuHeader onClose={onClose} />
                <div className="flex-grow flex flex-col overflow-y-auto">
                    <div>
                        <MobileMenu setActiveButtonNav={setActiveButtonNav} />
                        <MobileMenuOrganizations refetchDocuments={refetchDocuments} />
                    </div>
                    <div className="mt-auto">
                        <div className="flex flex-col gap-4 p-4 justify-center items-center ">
                            <MobileMenuHelp />
                            <CloseSesion />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const MobileMenuHeader: React.FC<MobileMenuHeaderProps> = ({ onClose }) => (
    <div className="flex justify-end items-center p-4 ">
        <button onClick={onClose}>
            <CloseIcon size={35} />
        </button>
    </div>
)

const MobileMenu: React.FC<MobileMenuNavigationProps> = ({ setActiveButtonNav }) => (
    <div className=' flex flex-col '>
        <p className='bodymbold text-NeutralNeutral2 px-4'>
            Menu
        </p>

        <nav className="p-4 space-y-4">
            {[
                { id: 0, icon: InboxIcon, label: "Documentos", route: ROUTES.VISOR_DOCUMENTOS },
                { id: 1, icon: InboxIcon, label: "Estadísticas", route: ROUTES.ESTADISTICAS },
                // { icon: GroupIcon, label: "Grupos" },
            ].map((item, index) => (
                <Link to={item.route}>
                    <button
                        key={index}
                        className="flex gap-5 items-center w-full p-2 text-left hover:bg-gray-100 rounded"
                        onClick={() => setActiveButtonNav(item.id)}
                    >
                        <item.icon />
                        <p className="w-5 h-5 mr-3 text-NeutralNeutral2 h6">{item.label}</p>
                    </button>
                </Link>

            ))}
        </nav>
    </div>

)
export interface MobileMenuOrganizationsProps {
    refetchDocuments?: (page: number) => void;
}

const MobileMenuOrganizations = ({ refetchDocuments }: MobileMenuOrganizationsProps) => {
    const { handleOrganizationSelect, logout } = useAuth()
    const { organizations, selectedOrg } = useAuthContext()
    return (
        <div className="p-4 ">
            <p className='bodymbold text-NeutralNeutral2 mb-4 '>
                Organizaciones
            </p>
            {organizations.map((org) => (
                <button
                    key={org.id}
                    onClick={() => handleOrganizationSelect(org, refetchDocuments)}
                    className="flex items-center w-full p-2 gap-1 text-left hover:bg-gray-100 rounded border-b last:border-b-0"
                >
                    <div className='w-[15%]'>
                        {org.urlImg ? (
                            <img src={org.urlImg} alt="" className="w-5 h-5 mr-3 rounded" />
                        ) : (
                            <div className="flex items-center justify-center p-1 mr-3  bg-gray-100 rounded" >

                                <OrganizationIcon size={20} color="#101216" />
                            </div>
                        )}
                    </div>

                    <p className={`w-[80%] bodylbold ${selectedOrg?.id === org.id ? " text-NeutralNeutral1" : " text-NeutralNeutral2"}`} >{org.name}</p>
                    <div className='shrink-0 w-[10%]'>

                        {selectedOrg?.id === org.id && <CheckCircle size={20} />}
                    </div>
                </button>
            ))}
        </div>
    )
}


const MobileMenuHelp: React.FC = () => (
    <div className=''>
        <Link to={ROUTES.FAQS} className="flex items-center justify-center w-full px-4 py-2 bodyl hover:bg-gray-100" role="menuitem">Ayuda</Link>
    </div>
)

const CloseSesion = () => {
    const { logout } = useAuth()
    return (
        <button className="flex items-center p-2 hover:bg-red-100 rounded"
            onClick={() => {
                logout()
            }}>
            <p className="text-red-500 bodyl" >
                Cerrar sesión</p>
        </button>
    )
}

