import type React from "react"
import { useParams } from "react-router-dom"
import { DocumentTemplateUploader } from "../components/DocumentTemplateUploader"
import { DocumentTemplateViewer } from "../components/DocumentTemplateViewer"
import { MenuDocumentTemplateManager } from "../components/MenuDocumentTemplateManager"
import { ValidationOptions } from "../components/ValidationOptions"
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"

export const CreateDocumentTemplate: React.FC = () => {
    const { nameTemplate } = useParams()
    const { fileTemplate } = useDocumentTemplateContext()

    return (
        <div
            className="flex bg-NeutralNeutral6"
            style={{ minHeight: "calc(100vh - 64px)", maxHeight: "calc(100vh - 96px)" }}
        >
            <div
                className={`${fileTemplate.length > 0 ? "w-10/12" : "w-full"} flex flex-col m-4 justify-center gap-2 items-center bg-white`}
            >
                {fileTemplate.length === 0 ? <DocumentTemplateUploader /> : <DocumentTemplateViewer />}
            </div>
            {fileTemplate.length > 0 && (
                <div className="flex flex-col gap-4 px-6 py-3 mt-1 bg-white overflow-y-auto max-w-2/12">
                    <MenuDocumentTemplateManager />
                    <ValidationOptions />
                </div>
            )}
        </div>
    )
}

