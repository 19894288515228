import { useMutation, useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import api from "../../shared/api/Axios";
import { apiLogOutAuthLXP } from "../../shared/api/AxiosAuthLXP";
import { SecurityEndpoints } from "../../shared/api/services/ConfigApi";
import { useAuthContext } from "../context/AuthContext";
import { LoginResponse } from "../pages/ExternalLoginPage";
import { GetMeRequest, Organization } from "../types/GetMeRequest";
import { LocalStorageKeys } from "../types/LocalStorageKeys";
import { useInfoLocalStorage } from "./useInfoLocalStorage";
import { useEffect } from "react";

const redirectUrl = process.env.REACT_APP_LOGIN_REDIRECT as string;

export const useAuth = () => {

    const { isAuthenticated, setUserInfoLocal, setUserOrganizationLogged, setExternalUserId, setMailUser, setNameUser, setOrganizations, setIsAuthenticated, selectedOrg, setSelectedOrg, externalUserId } = useAuthContext()
    const { getExternalUserIdLocalStorage, clearAllLocalStorage, getOrganizationsLocalStorage, setOrganizationsLocalStorage, setExternalUserIdLocalStorage, setMailUserLocalStorage, setUserOrganizationLoggedLocalStorage, setNameUserLocalStorage, getUserOrganizationLoggedLocalStorage, getMailUserLocalStorage, getNameUserLocalStorage } = useInfoLocalStorage()

    const logout = () => {
        logOutAuthLXP();
        Cookies.remove(LocalStorageKeys.accessToken)
        Cookies.remove(LocalStorageKeys.refreshToken)
        clearAllLocalStorage()
        setIsAuthenticated(false)
        window.location.href = redirectUrl;
    }

    const { refetch: logOutAuthLXP } = useQuery<null>({
        queryFn: async () => {
            const { data } = await apiLogOutAuthLXP.post<null>(SecurityEndpoints.LogOutAuth(externalUserId));
            return null;
        },
        queryKey: [],
        enabled: false,
        retry: false
    });

    const login = async (accessToken: string, refreshToken: string) => {
        Cookies.remove(LocalStorageKeys.accessToken)
        Cookies.remove(LocalStorageKeys.refreshToken)
        clearAllLocalStorage()
        Cookies.set(LocalStorageKeys.accessToken, accessToken, { expires: 1 / 24 }) // 1 hora
        //expires: 15 / (24 * 60 * 60) 15 segs
        Cookies.set(LocalStorageKeys.refreshToken, refreshToken, { expires: 7 }) // 7 días
        try {
            await getUserQuery.refetch()
            setIsAuthenticated(true)
        } catch (error) {
            console.error('Error al obtener datos del usuario:', error)
            logout()
        }
    }


    const getUserQuery = useQuery<GetMeRequest>({
        queryKey: ['user'],
        queryFn: async () => {
            const { data } = await api.get<GetMeRequest>(SecurityEndpoints.getMe())
            setOrganizationsLocalStorage(data.organizations)
            setOrganizations(data.organizations)
            setUserOrganizationLoggedLocalStorage(data.userOrganizationLogged)
            setUserOrganizationLogged(data.userOrganizationLogged)
            setNameUserLocalStorage(data.name)
            setNameUser(data.name)
            setMailUserLocalStorage(data.mail)
            setMailUser(data.mail)
            setExternalUserIdLocalStorage(data.externalUserId)
            setExternalUserId(data.externalUserId)
            return data;
        },
        enabled: false,
    })

    const handleOrganizationSelect = async (org: Organization, refetchDocuments?: (page: number) => void) => {
        if (org.id === selectedOrg?.id) return
        try {
            await changeOrganizationMutation.mutateAsync({ orgId: org.id, refetchDocuments })
            setSelectedOrg(org)
            setUserOrganizationLogged(org.userOrganizationId)
            // setIsOpen(false)
        } catch (error) {
            console.error("Error changing organization:", error)
        }
    }


    const changeOrganizationMutation = useMutation({
        mutationFn: async ({
            orgId,
            refetchDocuments
        }: { orgId: string; refetchDocuments?: (page: number) => void }): Promise<LoginResponse> => {
            const { data } = await api.post<LoginResponse>(`${SecurityEndpoints.changeOrganization()}?organizationId=${orgId}`)
            return data
        },
        onSuccess: (data, variables) => {
            login(data.accessToken, data.refreshToken)
            if (variables.refetchDocuments) {
                variables.refetchDocuments(1)
            }
        },
    })


    useEffect(() => {
        const accessToken = Cookies.get(LocalStorageKeys.accessToken)
        const refreshToken = Cookies.get(LocalStorageKeys.refreshToken)

        if (accessToken && refreshToken) {
            const storedOrganizations = getOrganizationsLocalStorage()
            const storedExternalUserId = getExternalUserIdLocalStorage()
            const storedMailUser = getMailUserLocalStorage()
            const storedUserOrganizationLogged = getUserOrganizationLoggedLocalStorage()
            const storedNameUser = getNameUserLocalStorage()
            if (
                storedOrganizations &&
                storedExternalUserId &&

                storedUserOrganizationLogged &&
                storedNameUser
            ) {

                setOrganizations(storedOrganizations)
                setExternalUserId(storedExternalUserId)
                setMailUser(storedMailUser)
                setUserOrganizationLogged(storedUserOrganizationLogged)
                setNameUser(storedNameUser)
                setIsAuthenticated(true)

                const organizaciones = storedOrganizations as Organization[]
                const orgToSelect =
                    organizaciones.find((org) => org.userOrganizationId === storedUserOrganizationLogged) ||
                    organizaciones[0] ||
                    null
                setSelectedOrg(orgToSelect)
            } else {
                getUserQuery.refetch()
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [])



    return { login, logout, handleOrganizationSelect }
}