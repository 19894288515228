import { Outlet } from "react-router-dom"
import { UnauthenticatedTopNav } from "../../auth/components/UnauthenticatedTopNav"


export const TopNavUnauthenticatedLayout: React.FC<{ title?: string, backButtonVisible?: boolean }> = ({ title, backButtonVisible }) => {

    return (
        <div className="flex flex-col h-lvh">
            <UnauthenticatedTopNav title={title} backButtonVisible={backButtonVisible} />
            <Outlet />
        </div>
    )
}
