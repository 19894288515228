import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import { ROUTES } from '../../router/routes'
import { useAuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { ExternalLoginPage } from '../pages/ExternalLoginPage';
import { TopNavUnauthenticatedLayout } from '../../shared/layouts/TopNavUnauthenticatedLayout';
import { TranslationKeys } from '../../shared/utils/i18n/TranslationKeys';
import { LoginErrorPage } from '../pages/LoginErrorPage';

export const AuthRoutes = () => {
    const { isAuthenticated, isAuthLoading } = useAuthContext();
    const { t } = useTranslation();
    return (
        <>
            <Route
                path={ROUTES.EXTERNALLOGIN}
                element={
                    !isAuthenticated ? (
                        <ExternalLoginPage />
                    ) : (
                        <Navigate to={ROUTES.VISOR_DOCUMENTOS} replace />
                    )
                } />

            <Route element={
                !isAuthLoading && (
                    <TopNavUnauthenticatedLayout
                        title={t(TranslationKeys.RegistroEnEducaSign)}
                    />
                )
            }>
                <Route path={ROUTES.LOGINERROR} element={<LoginErrorPage />} />
            </Route>

        </>
    )
}
