import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../router/routes";
import api from "../../shared/api/Axios";
import apiAuthLXP from "../../shared/api/AxiosAuthLXP";
import { SecurityEndpoints } from "../../shared/api/services/ConfigApi";
import { Skeleton } from "../../shared/components/Skeleton";
import { useAuthContext } from "../context/AuthContext";
import { useAuth } from "../hooks/useAuth";
import { useInfoLocalStorage } from "../hooks/useInfoLocalStorage";

export interface LoginResponse {
  accessToken: string
  refreshToken: string
}

export interface LoginCodeResponse {
  success: boolean
}


export interface RegisterNewUserRequest {
  externalId: string
}

export function ExternalLoginPage() {
  const { login } = useAuth();
  const { isAuthLoading, setIsAuthLoading } = useAuthContext();
  const [searchParams] = useSearchParams();
  const externalId = searchParams.get("key_id") as string;
  const code = searchParams.get("code") as string;
  const redirectUrl = process.env.REACT_APP_LOGIN_REDIRECT as string;
  const navigate = useNavigate();
  const { setExternalUserIdLocalStorage } = useInfoLocalStorage();

  const loginUser = async (id: string): Promise<LoginResponse> => {
    const { data } = await api.post<LoginResponse>(SecurityEndpoints.externalLogin(id));
    return data;
  };

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: async (data) => {
      const isLocalhost = window.location.hostname === "localhost";
      if (isLocalhost) {
        // Si está en localhost,  realiza el login
        login(data.accessToken, data.refreshToken);
      } else if (code && externalId) {
        // Si no está en localhost, realiza la validación del código
        const { data: success } = await refecthLoginCode();

        if (success) {
          login(data.accessToken, data.refreshToken);
        }
        else {
          window.location.href = redirectUrl;
        }
      }
      setIsAuthLoading(false)

    },
    onError: async () => {
      //Si la URL tiene los parámetros de code y key_id y llega al error es porque el usuario no está en la base de datos de EducaSign. 
      // En este punto, se manda un email para procesar el alta.
      if (externalId) {
        setExternalUserIdLocalStorage(externalId)
        navigate(ROUTES.LOGINERROR, { replace: true });
      }
    },
  });

  const { data, refetch: refecthLoginCode, isLoading: isLoadingQuery } = useQuery<boolean>({
    queryFn: async () => {
      const { data } = await apiAuthLXP.post<LoginCodeResponse>(SecurityEndpoints.LoginCodeAuth(code));
      return data.success;
    },
    queryKey: ["loginCode", code],
    enabled: false,
    retry: false
  });

  useEffect(() => {
    if (!externalId) {
      window.location.href = redirectUrl;
    } else {
      loginMutation.mutate(externalId);
    }
  }, [externalId]);


  const isLoading = loginMutation.isPending && isLoadingQuery;

  return (
    <div className="h-screen w-screen ">
      <div className="flex flex-col items-center w-full  h-full px-8 py-6 gap-5 md:flex md:flex-row md:justify-evenly md:items-center ">

        {!isAuthLoading && (
          <div className=" m-10">
            {Array(1)
              .fill(0)
              .map((_, i) => <Skeleton key={i} />)}
          </div>
        )
        }
      </div>
    </div>
  );
}